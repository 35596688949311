import Navbar from "~/components/organisms/Navbar";
import Footer from "~/components/Footer";

export default function Default(props) {
  const { children } = props;

  return (
    <>
      <Navbar floating={true} theme={"white"} />
      {children}
      <Footer />
    </>
  );
}
