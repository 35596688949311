import styles from "~/sass/molecules/Testimonial.module.scss";
import Card from "~/components/molecules/Card";
import StarRating from "~/components/molecules/StarRating";
import { createRef, useState, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";

export default function Testimonial({
  image,
  company,
  name,
  stars,
  date,
  content,
  isPersonalRelocationNetwork,
  link,
  circleColor,
  altText,
}) {
  const ref = createRef();
  const [showReadMore, setShowReadMore] = useState(false);
  const [readMore, setReadMore] = useState(false);

  useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setShowReadMore(true);
    }
  }, [ref]);

  const parseName = () => {
    const splitName = name.split(" ");
    let initials = [];
    for (let i = 0; i < splitName.length; i++) {
      initials.push(splitName[i][0]);
    }
    return initials.join(" ");
  };
  return (
    <Card className={styles["Testimonial"]}>
      <div className={styles["Testimonial__header"]}>
        <div
          className={`${styles["Testimonial__top-header"]} ${
            isPersonalRelocationNetwork && styles["--row"]
          }`}
        >
          <div className={styles["Testimonial__image"]}>
            {image ? (
              <picture>
                <img src={image} alt={altText} height={60} width={60} />
              </picture>
            ) : (
              <div
                className={`${styles["Testimonial__initials-circle"]} ${
                  styles[`--${circleColor}`]
                }`}
              >
                <h3>{parseName()}</h3>
              </div>
            )}
          </div>
          <div
            className={`${styles["Testimonial__info"]} ${
              isPersonalRelocationNetwork && styles["--reverse"]
            }`}
          >
            <div className={styles["Testimonial__info-company"]}>{company}</div>
            <div
              className={`${styles["Testimonial__info-name"]} ${
                isPersonalRelocationNetwork && styles["--bolder"]
              }`}
            >
              {name}
            </div>
          </div>
        </div>
        <div className={styles["Testimonial__meta"]}>
          <StarRating rating={stars} />
          <div className={styles["Testimonial__meta-date"]}>{date}</div>
        </div>
      </div>
      <div
        className={`${styles["Testimonial__content"]} ${
          readMore && styles["--read-more"]
        } ${isPersonalRelocationNetwork && styles["--left-align"]}`}
        ref={ref}
      >
        {content}
      </div>
      {!isPersonalRelocationNetwork ? (
        <div className={styles["Testimonial__button-wrapper"]}>
          {showReadMore && (
            <button
              className={`${styles["Testimonial__button"]} ${
                readMore ? styles["--active"] : styles["--inactive"]
              }`}
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {readMore ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
      ) : (
        <div className={styles["Testimonial__link-wrapper"]}>
          <a
            target={"_blank"}
            className={styles["Testimonial__link"]}
            href={link}
          >
            View on ShopperApproved.com
            <FontAwesomeIcon
              icon={faArrowUpRight}
              alt={"Shopper approved link"}
            />
          </a>
        </div>
      )}
    </Card>
  );
}
